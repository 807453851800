import React from "react";
import { Layout } from "../components/layout/layout";
import styled from "@emotion/styled";

const NotFoundPage = () => {
  return (
    <Layout title="Not Found | LIME" jpUrl="/" enUrl="/en/" mainWrap="index">
      <SWrapper>
        <h1>
          404
          <br />
          NOT FOUND
        </h1>
      </SWrapper>
    </Layout>
  );
};

export default NotFoundPage;

const SWrapper = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
  }
`;
